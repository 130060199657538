import axios from 'axios'
import { GOPAY_USERINFO, GOPAY_FILECONFIG, GOPAY_KTOKEN } from '@/assets/js/common.js'
import router from '@/router'
import { Toast } from 'vant'

// 创建 axios 实例
const service = axios.create({
    timeout: 20000,
    params:
        process.env.NODE_ENV === 'production'
            ? {
                  rd: Math.random()
              }
            : null
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json'
        config.headers['KToken'] = localStorage.getItem(GOPAY_KTOKEN) ? localStorage.getItem(GOPAY_KTOKEN) : ''

        return config
    },
    error => {
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 0) {
            return response.data
        } else {
            console.log('响应数据报错')
            return Promise.reject(response)
        }
    },
    error => {
        if (error.response.status === 401) {
            Toast.fail('您的登录已过期')
            localStorage.removeItem(GOPAY_USERINFO)
            localStorage.removeItem(GOPAY_FILECONFIG)
            localStorage.removeItem(GOPAY_KTOKEN)
            window.connection = null
            router.push('/Login')
            return
        }

        if (error.response) {
            return error.response.data
        } else {
            return Promise.reject(error)
        }
    }
)

export default service
