export default () => {
    return [
        {
            path: '/',
            redirect: '/Login'
        },
        /*********************************** 登录、注册 ***********************************/
        {
            path: '/Login',
            name: 'Login',
            component: () => import('@/views/Login.vue'),
            meta: {
                index: 1
            }
        },
        {
            path: '/Register',
            name: 'Register',
            component: () => import('@/views/Register.vue'),
            meta: {
                index: 2,
                title: '注册'
            }
        },
        {
            path: '/GetPw',
            name: 'GetPw',
            component: () => import('@/views/GetPw.vue'),
            meta: {
                index: 2,
                title: '找回密码'
            }
        },
        {
            path: '/Service',
            name: 'Service',
            component: () => import('@/views/Service.vue'),
            meta: {
                index: 999,
                title: '客服'
            }
        },

        /*********************************** 扫码支付 ***********************************/
        {
            path: '/QRScanner',
            name: 'QRScanner',
            component: () => import('@/views/QRScanner.vue'),
            meta: {
                index: 3.5,
                title: '付款码支付'
            }
        },
        {
            path: '/Payment',
            name: 'Payment',
            component: () => import('@/views/Payment.vue'),
            meta: {
                index: 3.5,
                title: '支付'
            }
        },

        /*********************************** 主页 ***********************************/
        {
            path: '/Home',
            name: 'Home',
            component: () => import('@/views/Home.vue'),
            meta: {
                index: 3,
                title: '首页'
            }
        },

        {
            path: '/Buy',
            name: 'Buy',
            component: () => import('@/views/Buy.vue'),
            meta: {
                index: 30,
                title: '我要买'
            }
        },
        {
            path: '/BuyPage',
            name: 'BuyPage',
            component: () => import('@/views/BuyPage.vue'),
            meta: {
                index: 300,
                title: '我要买'
            }
        },

        {
            path: '/Sell',
            name: 'Sell',
            component: () => import('@/views/Sell.vue'),
            meta: {
                index: 30,
                title: '我要卖'
            }
        },

        {
            path: '/MyDeity',
            name: 'MyDeity',
            component: () => import('@/views/MyDeity.vue'),
            meta: {
                index: 30,
                title: '我的挂单'
            }
        },
        {
            path: '/MyDeityPage',
            name: 'MyDeityPage',
            component: () => import('@/views/MyDeityPage.vue'),
            meta: {
                index: 300,
                title: '挂单详情'
            }
        },

        {
            path: '/MyOrder',
            name: 'MyOrder',
            component: () => import('@/views/MyOrder.vue'),
            meta: {
                index: 30,
                title: '我的订单'
            }
        },
        {
            path: '/MyOrderPage',
            name: 'MyOrderPage',
            component: () => import('@/views/MyOrderPage.vue'),
            meta: {
                index: 300,
                title: '订单详情'
            }
        },

        {
            path: '/Wallet',
            name: 'Wallet',
            component: () => import('@/views/Wallet.vue'),
            meta: {
                index: 30,
                title: '钱包记录'
            }
        },

        {
            path: '/BankInformation',
            name: 'BankInformation',
            component: () => import('@/views/BankInformation.vue'),
            meta: {
                index: 31,
                title: '收付款信息'
            }
        },
        {
            path: '/AddBankInformation',
            name: 'AddBankInformation',
            component: () => import('@/views/AddBankInformation.vue'),
            meta: {
                index: 300,
                title: '增加收付款信息'
            }
        },

        {
            path: '/Help',
            name: 'Help',
            component: () => import('@/views/Help.vue'),
            meta: {
                index: 30,
                title: '帮助'
            }
        },
        {
            path: '/Shimingrenzheng',
            name: 'Shimingrenzheng',
            component: () => import('@/views/Shimingrenzheng.vue'),
            meta: {
                index: 300,
                title: '实名认证'
            }
        },
        {
            path: '/Tianjiashoufukuan',
            name: 'Tianjiashoufukuan',
            component: () => import('@/views/Tianjiashoufukuan.vue'),
            meta: {
                index: 300,
                title: '添加收付款'
            }
        },
        {
            path: '/Maib',
            name: 'Maib',
            component: () => import('@/views/Maib.vue'),
            meta: {
                index: 300,
                title: '买币'
            }
        },
        {
            path: '/Maibi',
            name: 'Maibi',
            component: () => import('@/views/Maibi.vue'),
            meta: {
                index: 300,
                title: '卖币'
            }
        },
        {
            path: '/Chongzhi',
            name: 'Chongzhi',
            component: () => import('@/views/Chongzhi.vue'),
            meta: {
                index: 300,
                title: '充值'
            }
        },
        {
            path: '/Tixian',
            name: 'Tixian',
            component: () => import('@/views/Tixian.vue'),
            meta: {
                index: 300,
                title: '提现'
            }
        },

        /*********************************** 个人中心 ***********************************/
        {
            path: '/User',
            name: 'User',
            component: () => import('@/views/User.vue'),
            meta: { index: 4 }
        },

        {
            path: '/EditUser',
            name: 'EditUser',
            component: () => import('@/views/EditUser.vue'),
            meta: {
                index: 40,
                title: '个人资料编辑'
            }
        },

        {
            path: '/ChangePassword',
            name: 'ChangePassword',
            component: () => import('@/views/ChangePassword.vue'),
            meta: {
                index: 40,
                title: '设置密码'
            }
        },

        {
            path: '/ChangePayPassword',
            name: 'ChangePayPassword',
            component: () => import('@/views/ChangePayPassword.vue'),
            meta: {
                index: 40,
                title: '设置支付密码'
            }
        },
        {
            path: '/SellConfig',
            name: 'SellConfig',
            component: () => import('@/views/SellConfig.vue'),
            meta: {
                index: 40,
                title: '商户配置'
            }
        },
        {
            path: '/PassTypeSetting',
            name: 'PassTypeSetting',
            component: () => import('@/views/PassTypeSetting.vue'),
            meta: {
                index: 40,
                title: '设置授权码'
            }
        },
        {
            path: '/SellTransSell',
            name: 'SellTransSell',
            component: () => import('@/views/SellTransSell.vue'),
            meta: {
                index: 40,
                title: '商户互转'
            }
        },

        /*********************************** 特殊页面 ***********************************/
        {
            path: '/RealName',
            name: 'RealName',
            component: () => import('@/views/RealName.vue'),
            meta: {
                index: 999,
                title: '实名认证'
            }
        }

        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    ]
}
