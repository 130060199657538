import http from './http'
import { proxy, GOPAY_COOKIE, GOPAY_KTOKEN } from '@/assets/js/common.js'

// 获取图形验证码
export function GetImgCode() {
    return http({
        method: 'get',
        url: proxy + '/Api/GetImgCodeBase64'
        // responseType: 'blob'
    }).then(res => {
        localStorage.setItem(GOPAY_COOKIE, res.data.KCaptcha)
        res.data.image = 'data:image/png;base64,' + res.data.image
        return res
    })
}

// 获取资源服务器信息
export function GetFileConfig() {
    return http({
        method: 'get',
        url: proxy + '/File/GetFileConfig'
    })
}

// 找回密码
export function userGetPw(params) {
    return http({
        method: 'post',
        url: proxy + '/Login/FindPwd',
        headers: {
            KCaptcha: localStorage.getItem(GOPAY_COOKIE)
        },
        params
    })
}

// 用户注册
export function userRegister(params) {
    return http({
        method: 'post',
        url: proxy + '/Login/UserReg',
        headers: {
            KCaptcha: localStorage.getItem(GOPAY_COOKIE)
        },
        params
    }).then(res => {
        if (res.data) {
            localStorage.setItem(GOPAY_KTOKEN, res.data.KToken)

            // window.connection = new signalR.HubConnectionBuilder()
            //     .withUrl('http://localhost:15678/UserLink?KToken=' + res.data.KToken)
            //     .configureLogging(signalR.LogLevel.Information)
            //     .build()
            location.reload(true)
        }
        return res
    })
}

// 注册获取密保问题列表
export function GetPrivateQuestion() {
    return http({
        method: 'get',
        url: proxy + '/Home/getPrivateQuestion',
        headers: {
            KCaptcha: localStorage.getItem(GOPAY_COOKIE)
        }
    })
}

// 找回密码获取账号对应的密保问题
export function FindPwdQandA(account) {
    return http({
        method: 'get',
        url: proxy + '/Login/FindPwdQandA',
        headers: {
            KCaptcha: localStorage.getItem(GOPAY_COOKIE)
        },
        params: {
            account
        }
    })
}

// 发送短信验证码
export function SendSmsCode(mobile) {
    return http({
        method: 'post',
        url: proxy + '/Login/SendSmsCode',
        headers: {
            KCaptcha: localStorage.getItem(GOPAY_COOKIE)
        },
        params: {
            mobile
        }
    })
}

// 用户登录
export function userLogin({ username, password, imgcode }) {
    return http({
        method: 'post',
        url: proxy + '/Login/UserLogin',
        headers: {
            KCaptcha: localStorage.getItem(GOPAY_COOKIE)
        },
        params: {
            username,
            password,
            imgcode
        }
    }).then(res => {
        if (res.data) {
            localStorage.setItem(GOPAY_KTOKEN, res.data.KToken)

            // window.connection = new signalR.HubConnectionBuilder()
            //     .withUrl('http://localhost:15678/UserLink?KToken=' + res.data.KToken)
            //     .configureLogging(signalR.LogLevel.Information)
            //     .build()
            location.reload(true)
        }
        return res
    })
}

// 退出登录
export function userLogout() {
    return http({
        method: 'get',
        url: proxy + '/Login/LoginOut'
    })
}

// 获取个人信息
export function GetUserInfo() {
    return http({
        method: 'get',
        url: proxy + '/User/GetUserInfo',
        headers: {
            KToken: localStorage.getItem(GOPAY_KTOKEN) ? localStorage.getItem(GOPAY_KTOKEN) : ''
        }
    })
}

// 首页 Banner 图：
export function getIndexBanner() {
    return http({
        method: 'get',
        url: proxy + '/Home/getIndexBanner'
    })
}

// 修改登录密码
export function changePassword({ oldpass, newpass }) {
    return http({
        method: 'post',
        url: proxy + '/User/changePass',
        params: {
            oldpass,
            newpass
        }
    })
}

// 修改登录密码：
export function changePayPassword({ oldpass, newpass }) {
    return http({
        method: 'post',
        url: proxy + '/User/setdrawpass',
        params: {
            oldpass,
            newpass
        }
    })
}

// 修改个人信息：
export function changeUserInfo({ picurl, nickname, picurl_sell }) {
    return http({
        method: 'post',
        url: proxy + '/User/changeUserInfo',
        params: {
            picurl,
            nickname,
            picurl_sell
        }
    })
}

// 实名认证：
export function updRealname(name) {
    return http({
        method: 'post',
        url: proxy + '/User/updRealname',
        params: {
            name
        }
    })
}

// 获取银行编码：
export function getBankCode() {
    return http({
        method: 'get',
        url: proxy + '/User/getBankCode'
    })
}

// 获取银行卡列表：
export function getBankList() {
    return http({
        method: 'get',
        url: proxy + '/User/getBankList'
    })
}

// 新增卡片信息：
export function addBank({ type, real_name, account, bankcode, qrcode, pay_address }) {
    return http({
        method: 'post',
        url: proxy + '/User/addBank',
        params: {
            type,
            real_name,
            account,
            bankcode,
            qrcode,
            pay_address
        }
    })
}

// 删除卡片信息：
export function delBank(BankID) {
    return http({
        method: 'post',
        url: proxy + '/User/delBank',
        params: {
            BankID
        }
    })
}

// 上传图片：
export function Upload(files) {
    return http({
        method: 'post',
        url: proxy + '/File/Upload',
        headers: {
            'content-type': 'multipart/form-data'
        },
        data: files
    })
}

// 获取 我要买列表：
export function getOrderList({ amt, type }) {
    return http({
        method: 'get',
        url: proxy + '/User/getOrderList',
        params: {
            amt: amt ? amt : undefined,
            type: type ? type : undefined
        }
    })
}

// 我要买 下单：
export function IWannaBuy({ orderID, amt, remark, isSplit, paytype }) {
    return http({
        method: 'post',
        url: proxy + '/User/IWannaBuy',
        params: {
            orderID,
            amt,
            remark,
            isSplit,
            paytype
        }
    })
}

// 我要卖 出售：
export function IWannaSell({ paytype, amt, remark, isSplit }) {
    return http({
        method: 'post',
        url: proxy + '/User/IWannaSell',
        params: {
            paytype,
            amt,
            remark,
            isSplit
        }
    })
}

// 获取 挂单列表：
export function getSellOrderList({ page, s_dtt, e_dtt }) {
    return http({
        method: 'get',
        url: proxy + '/User/getSellOrderList',
        params: {
            page,
            s_dtt,
            e_dtt
        }
    })
}

// 我的挂单 导出接口
export function getSellOrderExcel({ page, s_dtt, e_dtt }) {
    return http({
        method: 'get',
        url: proxy + '/User/getSellOrderExcel',
        params: {
            page,
            s_dtt,
            e_dtt
        },
        responseType: 'blob'
    }).then(res => {
        let time = new Date().toLocaleString().replace(/[/|:|" "]/g, '_')
        let a = document.createElement('a')
        a.download = time + '_' + '我的挂单.xls'
        a.href = window.URL.createObjectURL(new Blob([res]))
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    })
}

// 获取 订单列表：
export function getAllOrderList({ page, s_dtt, e_dtt }) {
    return http({
        method: 'get',
        url: proxy + '/User/getAllOrderList',
        params: {
            page,
            s_dtt,
            e_dtt
        }
    })
}

// 订单列表 导出接口
export function getAllOrderExcel({ page, s_dtt, e_dtt }) {
    return http({
        method: 'get',
        url: proxy + '/User/getAllOrderExcel',
        params: {
            page,
            s_dtt,
            e_dtt
        },
        responseType: 'blob'
    }).then(res => {
        let time = new Date().toLocaleString().replace(/[/|:|" "]/g, '_')
        let a = document.createElement('a')
        a.download = time + '_' + '我的订单.xls'
        a.href = window.URL.createObjectURL(new Blob([res]))
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    })
}

// 获取 订单详情：
export function GetBuyOrderDetail(subID) {
    return http({
        method: 'get',
        url: proxy + '/User/GetBuyOrderDetail',
        params: {
            subID
        }
    })
}

// 卖家确认订单：
export function SellCheckOrder({ subID, state, bankID }) {
    return http({
        method: 'post',
        url: proxy + '/User/SellCheckOrder',
        params: {
            subID,
            state,
            bankID
        }
    })
}

// 买家取消订单：
export function BuyCancelOrder(subID) {
    return http({
        method: 'post',
        url: proxy + '/User/BuyCancelOrder',
        params: {
            subID
        }
    })
}

// 买家确认已转账：
export function BuyCheckOrder({ subID, bankID }) {
    return http({
        method: 'post',
        url: proxy + '/user/BuyCheckOrder',
        params: {
            subID,
            bankID
        }
    })
}

// 卖家确认打币：
export function SellTranOrder({ subID, drawpass }) {
    return http({
        method: 'post',
        url: proxy + '/user/SellTranOrder',
        params: {
            subID,
            drawpass
        }
    })
}

// 钱包记录：
export function getPayOrderList({ page, s_dtt, e_dtt }) {
    return http({
        method: 'get',
        url: proxy + '/User/getPayOrderList',
        params: {
            page,
            s_dtt,
            e_dtt
        }
    })
}

// 钱包记录 导出接口
export function getPayOrderExcel({ page, s_dtt, e_dtt }) {
    return http({
        method: 'get',
        url: proxy + '/User/getPayOrderExcel',
        params: {
            page,
            s_dtt,
            e_dtt
        },
        responseType: 'blob'
    }).then(res => {
        let time = new Date().toLocaleString().replace(/[/|:|" "]/g, '_')
        let a = document.createElement('a')
        a.download = time + '_' + '钱包记录.xls'
        a.href = window.URL.createObjectURL(new Blob([res]))
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    })
}

// 卖家取消挂单并退还资金：
export function SellReturnOrder(orderid) {
    return http({
        method: 'get',
        url: proxy + '/User/SellReturnOrder',
        params: {
            orderid
        }
    })
}

// 呼叫对方：
export function OrderNotify(subID) {
    return http({
        method: 'get',
        url: proxy + '/User/OrderNotify',
        params: {
            subID
        }
    })
}

// 暂停打币：
export function SellPauseTranCoin(subID) {
    return http({
        method: 'get',
        url: proxy + '/User/SellPauseTranCoin',
        params: {
            subID
        }
    })
}

// 获取支付订单信息：
export function getorderinfo(id) {
    return http({
        method: 'get',
        url: proxy + '/Pay/getorderinfo',
        params: {
            id
        }
    })
}

// 检查订单：
export function checkorder(id) {
    return http({
        method: 'get',
        url: proxy + '/Pay/checkorder',
        params: {
            id
        }
    })
}

// 展示用户余额：
export function PayGetUserInfo() {
    return http({
        method: 'get',
        url: proxy + '/Pay/GetUserInfo'
    })
}

// 输入支付密码：
export function payorder({ draw_password, content }) {
    return http({
        method: 'post',
        url: proxy + '/Pay/payorder',
        data: {
            token: '',
            draw_password,
            content
        }
    })
}

/**
 * 商户验证
 */
export function getSellVerify({ pass }) {
    return http({
        method: 'post',
        url: proxy + '/User/sell_verify',
        params: {
            pass
        }
    })
}

/**
 * 获取支付系统IP白名单列表
 */

export function getPayIpList() {
    return http({
        method: 'get',
        url: proxy + '/User/GetPayIpList'
    })
}

/**
 * 删除支付系统IP白名单列表
 * @param id
 */
export function delPayIp(params) {
    return http({
        method: 'post',
        url: proxy + '/User/DelPayIp',
        params
    })
}

/**
 * 新增或修改支付系统IP白名单列表
 * @param id IP地址
 * @param remark 备注
 */
export function addPayIp(params) {
    return http({
        method: 'post',
        url: proxy + '/User/AddPayIp',
        params
    })
}

/**
 * 切换商户权限验证方式
 */
export function changePassType(params) {
    return http({
        method: 'post',
        url: proxy + '/User/ChangePassType',
        params
    })
}

/**
 * 重新生成谷歌秘钥
 */
export function changeGooglePass() {
    return http({
        method: 'post',
        url: proxy + '/User/ChangeGooglePass'
    })
}

/**
 * 切换充值姓名校验开关
 */
export function changeRechcekType(params) {
    return http({
        method: 'post',
        url: proxy + '/User/ChangeRechcekType',
        params
    })
}

/**
 * 商户互转
 */
export function sellTransSell(params) {
    return http({
        method: 'post',
        url: proxy + '/User/SellTransSell',
        params
    })
}

/**
 * 切换代付姓名校验开关
 */
export function changeDrawcheckType(params) {
    return http({
        method: 'post',
        url: proxy + '/User/ChangeDrawcheckType',
        params
    })
}

// 客服页面退出需要请求的接口
export function ExitService() {
    return http({
        method: 'get',
        url: proxy + '/User/ExitService'
    })
}
