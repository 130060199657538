<template>
    <van-nav-bar :border="false" safe-area-inset-top v-if="isIOS() && isApp" style="height: 0; background: #3366ee;" />

    <div class="app">
        <van-nav-bar v-if="hasNavBar" :title="navBarTitle" left-arrow @click-left="routerBack">
            <!-- <template #right v-if="route.path === '/Buy'">
                <div @click="isShow = true">
                    <span>筛选</span>
                    <van-icon name="search" size="18" />
                </div>
            </template> -->

            <template #right v-if="ExcelArr.includes(route.path) && userInfo.USER_TYPE === '1'">
                <span class="Excel" @click="Excel">下载</span>
            </template>
        </van-nav-bar>

        <div class="inner-container">
            <router-view v-slot="{ Component }" :class="{ 'has-nav-bar': hasNavBar, 'has-tabbar': hasTabbar }">
                <transition :name="transitionName">
                    <component :is="Component" ref="childComponent" />
                </transition>
            </router-view>
        </div>

        <van-tabbar v-model="active" class="tabbar" v-if="hasTabbar">
            <van-tabbar-item @click="router.push('/Home')">
                <span>首页</span>
                <template #icon>
                    <span class="iconfont icon-a-iconpay_huaban1fuben"></span>
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="router.push('/QRScanner')">
                <span>{{ isApp ? '扫码支付' : '付款码支付' }}</span>
                <template #icon>
                    <span class="iconfont icon-a-iconpay_huaban1fuben2"></span>
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="router.push('/User')">
                <span>我的</span>
                <template #icon>
                    <span class="iconfont icon-a-iconpay_huaban1fuben3"></span>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import { reactive, toRefs, watch, nextTick, ref, computed, provide } from 'vue'
import { useWindowSize } from '@vant/use'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { GOPAY_USERINFO, GOPAY_FILECONFIG, GOPAY_KTOKEN, GOPAY_ROUTER } from '@/assets/js/common.js'
import { ExitService } from '@/api'
import { Dialog } from 'vant'

export default {
    setup() {
        const state = reactive({
            width: 0,
            height: 0,

            active: 0,
            isApp: true,
            transitionName: '',

            isConnectWebSocket: false,

            hasNavBar: false,
            navBarTitle: '',

            hasTabbar: false,

            ExcelArr: ['/MyDeity', '/MyOrder', '/Wallet']
        })

        const childComponent = ref(null)

        const router = useRouter()
        const route = useRoute()
        const store = useStore()

        const userInfo = computed(() => {
            return store.state.userInfo
        })

        const { width, height } = useWindowSize()
        state.width = width
        state.height = height

        provide('viewWidth', width)
        provide('viewHeight', height)

        if (typeof QRScanner !== 'object') {
            state.isApp = false
            document.getElementsByTagName('body')[0].className += 'prevent-drag'
            console.log('当前环境：浏览器 H5')
        } else {
            document.getElementsByTagName('body')[0].className += 'ios-body'
            state.isApp = true
            console.log('当前环境：APP webview')
        }

        // WebSocket
        try {
            window.connection.on('LoginOut', () => {
                localStorage.removeItem(GOPAY_USERINFO)
                localStorage.removeItem(GOPAY_FILECONFIG)
                localStorage.removeItem(GOPAY_KTOKEN)
                window.connection = null
                router.push('/Login')
            })

            window.connection.off('PrivateDialog')
            window.connection.on('PrivateDialog', ({ title, message, showConfirmButton, showCancelButton, confirmButtonText, cancelButtonText, toType, toParam }) => {
                Dialog.alert({
                    title,
                    message,
                    showConfirmButton,
                    showCancelButton,
                    confirmButtonText,
                    cancelButtonText
                })
                    .then(() => {
                        // 又多了两个参数
                        // toType: 'order', toParam:  123
                        if (toType === 'order' && route.path !== '/MyOrderPage') {
                            router.push({
                                path: '/MyOrderPage',
                                query: {
                                    subID: toParam
                                }
                            })
                        } else if (toType === 'order' && route.path === '/MyOrderPage') {
                            router.replace({
                                path: '/MyOrderPage',
                                query: {
                                    subID: toParam,
                                    rd: Math.random()
                                }
                            })
                        }
                    })
                    .catch(() => {
                        console.log('弹窗点击取消了')
                    })
            })
        } catch (error) {
            console.log(error)
        }

        watch(
            () => [route.path, route.meta],
            (to, from) => {
                let toPath = to[0]
                let toMeta = to[1]

                let fromMeta
                if (from) fromMeta = from[1]

                let curPath = toPath.toLocaleLowerCase()

                // 设置下方 tabbar 下标
                if (curPath === '/home') state.active = 0
                if (curPath === '/qrscanner') state.active = 1
                if (curPath === '/user') state.active = 2

                // 显示隐藏 nav-bar
                let hiddeList = ['/login', '/home', '/user']
                state.hasNavBar = hiddeList.includes(curPath) ? false : true
                state.navBarTitle = toMeta.title

                // 显示隐藏 tabbar
                let showList = ['/home', '/user']
                state.hasTabbar = showList.includes(curPath) ? true : false

                // 动态计算子组件高度
                nextTick(() => {
                    let container = document.getElementsByClassName('inner-container')[0]
                    if (container) {
                        if (curPath === '/' || curPath === '/login') return

                        if (state.hasNavBar) {
                            container.style.height = state.height - 46 + 'px'
                        }

                        if (state.hasTabbar) {
                            container.style.height = state.height - 50 + 'px'
                        }
                    }
                })

                // WebSocket
                try {
                    let unWs = ['/', '/login', '/register']
                    if (!unWs.includes(curPath) && state.isConnectWebSocket === false) {
                        window.start()
                        state.isConnectWebSocket = true
                    }
                } catch (e) {
                    console.log('WebSocket App watch 报错')
                    console.log(e)
                }

                // 路由跳转过渡动画
                if (to && from) {
                    if (toMeta.index > fromMeta.index) {
                        state.transitionName = 'van-slide-left'
                    } else if (toMeta.index < fromMeta.index) {
                        state.transitionName = 'van-slide-right'
                    } else {
                        state.transitionName = ''
                    }
                }
            },
            { immediate: true }
        )

        // 需求：
        // Home => Buy => BuyPage => MyOrderPage
        // 回退：MyOrderPage => MyOrder => Home
        router.beforeEach((to, from, next) => {
            if (from.path === '/BuyPage' && to.path === '/MyOrderPage') {
                localStorage.setItem(GOPAY_ROUTER, '1')
            }

            next()
        })

        const routerBack = () => {
            if (route.path === '/Service') {
                ExitService()
            }

            // Home => Buy => BuyPage => MyOrderPage
            // 回退：MyOrderPage => MyOrder => Home
            if (route.path === '/MyOrderPage' && localStorage.getItem(GOPAY_ROUTER) === '1') {
                router.replace('/MyOrder')
            } else if (route.path === '/MyOrder' && localStorage.getItem(GOPAY_ROUTER) === '1') {
                router.replace('/Home')
                localStorage.removeItem(GOPAY_ROUTER)
            } else {
                router.back()
            }
        }

        const Excel = () => {
            childComponent.value.Excel()
        }

        const isIOS = () => {
            let u = navigator.userAgent
            return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        }

        return {
            ...toRefs(state),
            router,
            route,
            routerBack,
            userInfo,
            Excel,
            childComponent,
            isIOS
        }
    }
}
</script>

<style lang="scss">
.app {
    position: relative;

    .Excel {
        padding: 5px 15px;
        border-radius: 30px;
        color: #fff;
        background: $skin_color;
    }

    .has-nav-bar {
        height: 100%;
        overflow: auto;
    }

    .has-tabbar {
        height: 100%;
        /*overflow: auto;*/
    }

    .inner-container {
        -webkit-overflow-scrolling: touch;
    }
    .inner-container::-webkit-scrollbar {
        display: none;
    }

    .tabbar {
        .iconfont {
            font-size: 25px;
        }
    }
}
</style>

<style>
.icon-a-payicon101_zhuanqu_huaban1fuben63:before {
    content: '\e649';
}
.icon-a-payicon101_zhuanqu_huaban1fuben65:before {
    content: '\e64c';
}
.van-tabbar-item {
    color: #bdbdbd !important;
}
.van-tabbar-item.van-tabbar-item--active {
    color: #3366ee !important;
}
.van-tabbar-item__icon {
    min-height: 25px;
    position: relative;
    z-index: 1;
}
.icon-a-iconpay_huaban1fuben2 {
    position: absolute;
    z-index: 2;
    left: -28px;
    top: -30px;
    border: #fff 5px solid;
    background-color: #fff;
    border-radius: 50%;
}
.icon-a-iconpay_huaban1fuben2:before {
    content: '\e60b';
    font-size: 46px;
    color: #3366ee;
}
</style>
