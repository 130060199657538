import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.scss'

import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/css/index.css'
import { GOPAY_KTOKEN, GOPAY_APPID } from '@/assets/js/common.js'

const signalR = require('./assets/js/signalr.min.js')

const app = createApp(App)

app.use(store)
    .use(router)
    .use(Vant)

// WebSocket
window.checkInterval = null

window.connection = new signalR.HubConnectionBuilder()
    .withUrl('http://localhost:15678/UserLink?KToken=' + localStorage.getItem(GOPAY_KTOKEN))
    .configureLogging(signalR.LogLevel.Information)
    .build()
window.connection.onclose(async () => {
    clearInterval(window.checkInterval)
    // console.log('掉线了，即将自动重连')
    await window.start()
})

window.start = async function() {
    // console.log('WebSocket start')
    try {
        await window.connection.start().then(() => {
            console.log('SignalR Connected.')
            if (!!window.checkInterval) {
                clearInterval(window.checkInterval)
            }
            window.checkInterval = setInterval(() => {
                window.onlineCheck()
            }, 30 * 1000)
        })
    } catch (err) {
        // console.log('start 错误，即将自动重连')
        // console.log(err)
        setTimeout(window.start, 5000)
    }
}
window.onlineCheck = async function() {
    try {
        if (window.connection.connectionState == 'Disconnected') {
            window.start()
        } else {
            window.connection.invoke('C')
        }
    } catch (err) {
        // console.log(err)
    }
}
// APP 唤醒
window.handleOpenURL = function(url) {
    let arr = url.split('=')
    if (arr.length) {
        let id = arr[1]
        localStorage.setItem(GOPAY_APPID, id)
    }
}

// 阻止全局滑动
window.touchmoveListener = function(e) {
    e.preventDefault()
}
document.addEventListener('touchmove', window.touchmoveListener, { passive: false })

app.mount('#app')
// document.addEventListener(
//     'deviceready',
//     function() {
//         app.mount('#app')
//     },
//     false
// )
// document.addEventListener(
//     'resume',
//     function() {
//         let id = localStorage.getItem(GOPAY_APPID)
//         if (id) {
//             let path = router.currentRoute.value.path
//             if (path === '/QRScanner') {
//                 router.replace({ path: '/QRScanner', query: { id } })
//             } else {
//                 router.push({ path: '/QRScanner', query: { id } })
//             }
//             localStorage.removeItem(GOPAY_APPID)
//         }
//     },
//     false
// )
