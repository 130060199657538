import { Toast } from 'vant'
import Clipboard from 'clipboard'

// 请求地址前缀
// export const proxy = process.env.NODE_ENV === 'production' ? 'https://localhost:15678' : '/api'
export const proxy = process.env.NODE_ENV === 'production' ? 'http://localhost:15678' : '/api'

// 登录、注册的 cookie
export const GOPAY_COOKIE = 'gopay_cookie'

// 维持用户登录状态，所有请求都要带上的的 token
export const GOPAY_KTOKEN = 'gopay_KToken'

// APP 唤醒 id 参数存储
export const GOPAY_APPID = 'gopay_appid'

// 个人信息
export const GOPAY_USERINFO = 'gopay_userInfo'

// 资源服务器信息
export const GOPAY_FILECONFIG = 'gopay_fileConfig'

// 路由回退控制
export const GOPAY_ROUTER = 'gopay_router'

export const isApp = () => {
    if (typeof QRScanner !== 'object') {
        console.log('当前环境：浏览器 H5')
        return false
    } else {
        console.log('当前环境：APP webview')
        return true
    }
}

// 复制文字
export const clipboardText = className => {
    let clipboard = new Clipboard(className)

    clipboard.on('success', e => {
        console.log('复制成功: ', e)
        Toast.success('复制成功')
        clipboard.destroy()
    })

    clipboard.on('error', e => {
        console.log('复制失败: ', e)
        Toast.fail('该手机权限不支持复制功能')
        clipboard.destroy()
    })
}

// 数组去重
export const arrayUnique = (arr, name) => {
    let hash = {}
    return arr.reduce((item, next) => {
        hash[next[name]] ? '' : (hash[next[name]] = true && item.push(next))
        return item
    }, [])
}

// 去顶部
export const toTop = () => {
    let innerContainer = document.getElementsByClassName('inner-container')[0]
    if (innerContainer) innerContainer.scrollTop = 0
}

export const changeTime = time => {
    return time < 10 ? '0' + time : time
}

// 时间方法: 获取当天的 开始时间, 结束时间
export const getTodayDate = () => {
    let todayDate = new Date()
    let year = todayDate.getFullYear()
    let month = todayDate.getMonth() + 1
    let day = todayDate.getDate()
    let YearMonthDay = year + '-' + changeTime(month) + '-' + changeTime(day)
    let startTime = YearMonthDay
    let endTime = YearMonthDay
    return [startTime, endTime]
}

// 时间方法: 获取昨天的 开始时间, 结束时间
export const getYesterdayDate = () => {
    let todayDate = new Date()
    let yesterDay = new Date(todayDate.getTime() - 24 * 60 * 60 * 1000)
    let year = yesterDay.getFullYear()
    let month = yesterDay.getMonth() + 1
    let day = yesterDay.getDate()
    let YearMonthDay = year + '-' + changeTime(month) + '-' + changeTime(day)
    let startTime = YearMonthDay
    let endTime = YearMonthDay
    return [startTime, endTime]
}

// 时间方法: 获取本周的 开始时间, 结束时间
export const getCurrentWeek = () => {
    let todayDate = new Date()
    let week = todayDate.getDay()
    let oneDay = 1000 * 60 * 60 * 24
    let minusDay = week !== 0 ? week - 1 : 6
    let Monday = new Date(todayDate.getTime() - minusDay * oneDay)
    let Sunday = new Date(Monday.getTime() + 6 * oneDay)
    let MondayYear = Monday.getFullYear()
    let MondayMonth = Monday.getMonth() + 1
    let MondayDay = Monday.getDate()
    let SundayYear = Sunday.getFullYear()
    let SundayMonth = Sunday.getMonth() + 1
    let SundayDay = Sunday.getDate()
    let startTime = MondayYear + '-' + changeTime(MondayMonth) + '-' + changeTime(MondayDay)
    let endTime = SundayYear + '-' + changeTime(SundayMonth) + '-' + changeTime(SundayDay)
    return [startTime, endTime]
}

// 时间方法: 获取本月的 开始时间, 结束时间
export const getCurrentMonth = () => {
    let todayDate = new Date()
    let currentYear = todayDate.getFullYear()
    let currentMonth = todayDate.getMonth()
    let firstDay = new Date(currentYear, currentMonth, 1)
    if (currentMonth === 11) {
        currentYear++
        currentMonth = 0
    } else {
        currentMonth++
    }
    let nextMonthFirstDay = new Date(currentYear, currentMonth, 1)
    let oneDay = 1000 * 60 * 60 * 24
    let lastDay = new Date(nextMonthFirstDay.getTime() - oneDay)
    let firstDayYear = firstDay.getFullYear()
    let firstDayMonth = firstDay.getMonth() + 1
    let firstDayDay = firstDay.getDate()
    let lastDayYear = lastDay.getFullYear()
    let lastDayMonth = lastDay.getMonth() + 1
    let lastDayDay = lastDay.getDate()
    let startTime = firstDayYear + '-' + changeTime(firstDayMonth) + '-' + changeTime(firstDayDay)
    let endTime = lastDayYear + '-' + changeTime(lastDayMonth) + '-' + changeTime(lastDayDay)
    return [startTime, endTime]
}

// 获取指定时间段年月日
export const getDate = date => {
    let todayDate = new Date(date)
    let year = todayDate.getFullYear()
    let month = todayDate.getMonth() + 1
    let day = todayDate.getDate()
    return year + '-' + changeTime(month) + '-' + changeTime(day)
}
