import { createRouter, createWebHashHistory } from 'vue-router'
import createRoute from './routes.js'
import { GOPAY_KTOKEN, GOPAY_USERINFO } from '@/assets/js/common.js'

const routes = createRoute()

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

const whiteList = ['/login', '/register', '/getpw', '/service'] // 白名单：没登录就可以进的页面

router.beforeEach((to, from, next) => {
    const isLogin = localStorage.getItem(GOPAY_KTOKEN) ? true : false

    let toPath = to.path.toLocaleLowerCase()

    if (isLogin) {
        if (whiteList.includes(toPath) && toPath !== '/home' && toPath !== '/service') {
            next('/Home')
        } else {
            next()
        }
    } else {
        if (whiteList.includes(toPath)) {
            next()
        } else {
            next(`/Login`)
        }
    }
})

export default router
