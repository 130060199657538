import { createStore } from 'vuex'
import { GOPAY_USERINFO, GOPAY_FILECONFIG } from '@/assets/js/common.js'
import { GetUserInfo, GetFileConfig } from '@/api'

export default createStore({
    state: {
        // 获取个人信息
        userInfo: localStorage.getItem(GOPAY_USERINFO)
            ? JSON.parse(localStorage.getItem(GOPAY_USERINFO))
            : {
                  ACC_BAL: 0,
                  ACC_BAL_SALE: 0,
                  ID: 0,
                  ISSET_DRAWPASS: 0,
                  ISSET_REALNAME: 0,
                  KPAY_ADDR: '',
                  NICK_NAME: '',
                  SALE_BAL: 0,
                  TRADE_BAL: 0,
                  USER_NAME: '',
                  USER_PIC: '',
                  USER_TYPE: ''
              },

        // 获取资源服务器信息
        fileConfig: localStorage.getItem(GOPAY_FILECONFIG) || ''
    },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setFileConfig(state, payload) {
            state.fileConfig = payload
        }
    },
    actions: {
        // 获取个人信息
        async getUserInfo(ctx, isForce) {
            const local_userInfo = localStorage.getItem(GOPAY_USERINFO)
            if (local_userInfo && !isForce) {
                ctx.commit('setUserInfo', local_userInfo)
            } else {
                const { data } = await GetUserInfo()
                ctx.commit('setUserInfo', data)
                localStorage.setItem(GOPAY_USERINFO, JSON.stringify(data))
            }
        },

        // 获取资源服务器信息
        async getFileConfig(ctx) {
            const local_fileConfig = localStorage.getItem(GOPAY_FILECONFIG)
            if (local_fileConfig) {
                ctx.commit('setFileConfig', local_fileConfig)
            } else {
                const { data } = await GetFileConfig()
                ctx.commit('setFileConfig', data)
                localStorage.setItem(GOPAY_FILECONFIG, data)
            }
        }
    }
})
